const PathConstants = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  DASHBOARD: "/dashboard", //for driver/operator
  ACCOUNT: "/account", //for user
  CREATE_RIDE: "/addride",
  BOOK_RIDE: "/book/ride",
  BOOKING_DETAIL: "/booking-detail",
  RIDE_DETAIL: "/trip-detail",
  SEARCH_RESULTS: "/search",
  USER_PROFILE: "/user/profile",
  USER_BOOKINGS: "/user/bookings",
  EDIT_PROFILE: "/profile/update",
  ABOUT: "/about",
  PAGE403: "/unauth",
  PAGE404: "/page404",
  EMAIL_VERIFY: "/email/verify",
  FORGOT_PASSWORD: "/password/forgot",
  CONTACT: "/contactus",

  COTRAVEL_SEARCH_RESULTS: "/cotravel/search",
  COTRAVEL_CREATE_QUERY: "/cotravel/query",

  ADMIN_PENAL: "/penal/admin"

};

export default PathConstants;
