import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useService from "../../hooks/useService";
import { throwError } from "rxjs";
import CoTravelSearchForm from "../Common/SearchComponent/CoTravelSearchForm";
import CoTravelService from "../../Services/CoTravelService";
import CoTravelQueryListComponent from "./CoTravelQueryListComponent";

export default function CoTravelSearchResults() {
  const location = useLocation();
  const state = location.state ? location.state : {};
  const [loading, setLoading] = useState(2);
  const [queries, setQueries] = useState([]);
  const [nearbyQueries, setNearbyQueries] = useState([]);
  const coTravelService = useService(CoTravelService);

  useEffect(() => {
    if (state && state.inputs && Object.entries(state.inputs).length > 0) {
      setLoading(1);
      coTravelService.search(state.inputs).subscribe({
        next: response => {
          if (response.status) {
            setQueries(response.result);
          }
          setLoading(0);
        },
        error: err => {
          // alert("network error");
          setLoading(0);
          return throwError(() => err);
        },
      });
      var params = state.inputs;
      params = ({ ...state.inputs, nearby: true });
      coTravelService.search(params).subscribe({
        next: response => {
          if (response.status) {
            setNearbyQueries(response.result);
          }
          setLoading(0);
        },
        error: err => {
          setLoading(0);
        },
      });
    }
  }, [state.refresh]);
  // state.refresh;
  // state.inputs,
  return (
    <div>
      <CoTravelSearchForm inputs={state?.inputs}></CoTravelSearchForm>
      {loading === 1 && (
        <div className="mt-5 1 text-center text-success">
          <span className="spinner-border" role="status"></span>
          <strong>Just a moment, searching rides for you...</strong>
        </div>
      )}
      {loading === 0 &&
        <CoTravelQueryListComponent
          queryList={{
            queries: queries,
            inputs: {
              from: state.inputs?.from,
              to: state.inputs?.to,
              date: state.inputs?.date,
              paxcount: state.inputs?.paxcount
              // departure: dateInputRef?.current?.value,
            },
          }}
        />

      }
      <hr className="mb-3"></hr>
      {loading === 0 &&
        <CoTravelQueryListComponent
          queryList={{
            queries: nearbyQueries,
            inputs: {
              from: state.inputs?.from,
              to: state.inputs?.to,
              date: state.inputs?.date,
              paxcount: state.inputs?.paxcount,
              nearby: true
            },
          }}
        />
      }
    </div>
  );
}
