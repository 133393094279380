import { EMPTY, Observable, throwError } from "rxjs";
import { MessageContextProps } from "../contexts/MessageContext";
import ErrorHandling from "./ErrorHandling";
import IService from "./IService";

import api from "../config/Api";
import { catchError } from "rxjs/operators";
import { User } from "../models/User";
import AuthUser from "../Components/Common/AuthUser";
import Ride from "../models/Ride";
import { map } from "rxjs/operators";
import RideStatus from "../constants/RideStatus";
import RequestTypes from "../constants/RequestConstants";
import RequestConstants from "../constants/RequestConstants";

export default class CoTravelService extends ErrorHandling implements IService {
  constructor(context: MessageContextProps) {
    super(context);
  }
  requestConstants = RequestConstants();

  search<Ride>(query: any): Observable<Ride[]> {
    return api.get<Ride[]>(`/cotravel/search`, undefined, query).pipe(
      // map((result: any) => result),
      // map(obj => ({name: obj.name, address: obj.address})),
      catchError((err: any) => {
        // console.log(err);
        this.handleError("Ride error");
        // return throwError(() => new Error("test"));
        // throw err;
        // return throwError(err); //Rethrow it back to component
        return throwError(() => err);
      })
    );
  }
  get<Ride>(id: string): Observable<Ride[]> {
    return api.get<Ride[]>(`/rides/` + id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        console.log(err);
        return [];
      })
    );
  }
  createQuery<Ride>(obj: object): Observable<Ride> {
    // console.log(obj);
    let response = api
      .post<any>(`/cotravel/query`, obj)
      .pipe();
    return response;
  }
  myQueries<Ride>(filters: object): Observable<Ride> {
    console.log(filters);
    let response = api
      .get<any>(`/cotravel/my/queries`, undefined, filters)
      .pipe();
    return response;
  }
  post<Ride>(obj: object): Observable<Ride> {
    console.log(obj);
    let response = api
      .post<any>(`/cotravel/request`, obj)
      .pipe();
    return response;
  }
  makeRequest<Ride>(obj: object): Observable<Ride> {
    console.log(obj);
    let response = api
      .post<any>(`/cotravel/request`, obj)
      .pipe();
    return response;
  }
  patch<T>(arg: T): Observable<T[]> {
    throw new Error("Method not implemented.");
  }
  myRequests<Ride>(query: any, requestType: string): Observable<Ride[]> {
    let url = "";
    if (requestType === this.requestConstants.RequestTypes.SENT) {
      url = `/cotravel/request/raised`;
    } else if (requestType === this.requestConstants.RequestTypes.RECEIVED) {
      url = `/cotravel/request/received`;
    }
    return api.get<Ride[]>(url, undefined, query).pipe(
      // map((result: any) => result),
      // map(obj => ({name: obj.name, address: obj.address})),
      catchError((err: any) => {
        // console.log(err);
        this.handleError("Ride error");
        // return throwError(() => new Error("test"));
        // throw err;
        // return throwError(err); //Rethrow it back to component
        return throwError(() => err);
      })
    );
  }
  delete<T>(id: string): Observable<T[]> {
    let response = api.delete<any>(`/rides`, id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        // console.log(err);
        throw err;
      })
    );
    // console.log(response);
    return response;
  }

  cancel<T>(id: string): Observable<T[]> {
    return this.takeRequestAction(id, RideStatus.CANCELLED);
  }

  takeRequestAction<T>(req_id: string, status: string): Observable<T[]> {
    let url = "";
    if (status === this.requestConstants.Statuses.ACCEPTED) {
      url = `/cotravel/request/accepted`;
    } else if (status === this.requestConstants.Statuses.REJECTED) {
      url = `/cotravel/request/rejected`;
    }

    let response = api
      .post<any>(url, { id: req_id })
      .pipe(
        catchError((err: any) => {
          this.handleError("error");
          // console.log(err);
          throw err;
        })
      );
    // console.log(response);
    return response;
  }
}
