function RequestConstants() {
  const RequestTypes = {
    SENT: "S",
    RECEIVED: "R",
    SENT_STRING: "Sent",
    RECEIVED_STRING: "Received"
  };

  function getStatusString(status) {
    if (status === Statuses.ACCEPTED) {
      return Statuses.ACCEPTED_STRING;
    } else if (status === Statuses.REJECTED) {
      return Statuses.REJECTED_STRING;
    } else {
      return Statuses.PENDING_STRING;
    }
  }

  const Statuses = {
    ACCEPTED: "A",
    REJECTED: "R",

    PENDING_STRING: "Pending",
    ACCEPTED_STRING: "Accepted",
    REJECTED_STRING: "Rejected",

  };
  return {
    Statuses: Statuses,
    getStatusString,
    RequestTypes: RequestTypes
  };
}
export default RequestConstants;
