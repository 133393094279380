import { Navigate, useLocation } from "react-router-dom";
import AuthUser from "./AuthUser";
import RolesConstants from "../../constants/RolesConstants";
import PathConstants from "../../routes/PathConstants";

export default function RequireOperator({ children }) {
  const { user } = AuthUser();
  const prevLocation = useLocation();
  var authed = user ? true : false;
  var isOperator =
    authed && user.role === RolesConstants.OPERATOR ? true : false;
  var isAdmin =
    authed && user.role === RolesConstants.ADMIN ? true : false;
  console.log(authed);
  return (isOperator === true || isAdmin === true) ? (
    children
  ) : authed ? (
    <Navigate to={PathConstants.HOME} replace />
  ) : (
    <Navigate
      to={PathConstants.LOGIN}
      state={{ redirectTo: prevLocation }}
      replace
    />
  );
}
