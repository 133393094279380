import { useState } from "react";
import "../../../styles/search.css";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import PathConstants from "../../../routes/PathConstants";
import RideService from "../../../Services/RideService";
import useService from "../../../hooks/useService";

import Select from "react-select";
import { toast } from "react-toastify";
import FiltersConstants from "../../../constants/FiltersConstants";
import AuthUser from "../AuthUser";
import RolesConstants from "../../../constants/RolesConstants";
import AddNewLocationDialog from "../../dialogs/AddNewLocationDialog";

export default function CoTravelSearchForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state ? location.state : {};
  const filtersConstants = FiltersConstants();
  const { user } = AuthUser();
  const [validated, setValidated] = useState(false);
  const [date, setDate] = useState();
  const [inputs, setInputs] = useState(
    // state && state.inputs ? state.inputs :
    {}
  );
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialMsg, setInitialMsg] = useState(false);
  const [errors, setErrors] = useState({ from: {}, to: {}, date: {} });
  const [openNewLocationDialog, setOpenNewLocationDialog] = useState(false);
  const rideService = useService(RideService);
  const reinitializeErrors = () => {
    setErrors({ from: {}, to: {}, date: {} });
    // setErrors(values => ({ ...values, [name]: value }));
  };
  const setInitialValues = event => {
    setLocations([]);
    setInitialMsg(true);
  };
  const searchLocation = event => {
    // setInitialMsg(false);
    // console.log(event);
    // return;
    reinitializeErrors();
    if (event.altKey || event.ctrlKey || event.shiftKey) {
      return;
    }

    var value = event;//.target.value + event.key;
    if (!value || value.length < 4) {
      return;
    }
    setLoading(true);
    // return;
    // setInitialMsg(false);
    rideService.searchLocation(value).subscribe({
      next: response => {
        if (response.status !== 0) {
          // console.log(response.result);
          var locations = [];
          response.result.map(location => {
            return locations.push({
              label: location.city,
              value: location.city,
              state: location.state,
              landmark: location.landmark,
              id: location._id,
            });
          });
          // console.log(locations);
          setLocations(locations);
          setLoading(false);
          setInitialMsg(false);
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };

  const notFoundMessage = event => {
    if (loading) {
      return `Loading`;
    } else if (!loading && !initialMsg) {
      // && (!locations || location.length <= 0)) {
      return "No result";
    } else {
      return "Search";
    }
  };
  const getSelectOptionLabel = option => {
    // console.log(option);
    //label = address, name = sec name, value = prim name
    // return option.label + " -> " + option.state + " (" + option.landmark + ")";
    let landmark = option.landmark ? option.landmark + ", " : "";
    return landmark + option.label + ", " + option.state;
    // return option.value + " -> " + option.name + " // " + option.label;
    // return <div>test</div>;
  };
  const handleSelectChange = (event, type) => {
    reinitializeErrors();
    const name = type;
    var value = event?.id;
    if (name === 'to' && inputs && inputs.from === value
      || name === 'from' && inputs && inputs.to === value) {
      toast.warning("To and From locations can't be same.");
      return;
    }
    setInputs(values => ({ ...values, [name]: value }));
  };
  const handleChange = event => {
    // console.log(event);
    const name = event?.target?.name;
    var value = event?.target?.value;
    if (name === "date") {
      handleDateChange(event);
    } else if (name === "paxcount") {
      value = parseInt(value);
    }
    setInputs(values => ({ ...values, [name]: value }));
    setInitialMsg(true);
  };

  const getDepartureTimeForSubmission = date => {
    // let currentDateMillisec = new Date(new Date().toDateString()).getTime();
    // let currentTimeMillisecDiff =
    //   new Date().getTime() - new Date(new Date().toDateString()).getTime();
    var selectedDateMilisec = new Date(
      new Date(date).toDateString()
    ).getTime();
    let value = selectedDateMilisec;
    // if (selectedDateMilisec < 12 * 60 * 60 * 1000 + currentDateMillisec)
    //   // + diff;
    //   value = selectedDateMilisec + currentTimeMillisecDiff;
    return value;
  };
  const handleDateChange = event => {
    const val = event.target.value;
    setDate(val);
    console.log(val);
  };
  const getSearchResults = event => {
    let queryInputs = inputs;
    // {
    //   from: inputs.from,
    //   to: inputs.to,
    //   date: inputs.date,
    //   paxcount: inputs.paxcount
    // };
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      var name = "",
        value = {};
      event.preventDefault();
      if (!queryInputs.from) {
        name = "from";
        value = { required: true };
        setErrors(values => ({ ...values, [name]: value }));
      }
      if (!queryInputs.to) {
        name = "to";
        value = { required: true };
        // console.log("null");
        setErrors(values => ({ ...values, [name]: value }));
      }
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      // setErrors(values => ({ ...values, [name]: value }));
      return;
    }
    let date = getDepartureTimeForSubmission(queryInputs?.date);
    if (date < new Date().getTime()) {
      alert("Departure cannot be in past");
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    } else {
      queryInputs.date = date;
      console.log(inputs);
      event.preventDefault();
      navigate(PathConstants.COTRAVEL_SEARCH_RESULTS, {
        state: {
          inputs: queryInputs,
          refresh: Math.random(),
          query: queryInputs,
        },
        // replace: true,
      });
    }
  };
  const createLocationDialog = () => {
    setOpenNewLocationDialog(true);
  };
  function handleConfirmationFromChild(confirmation) {
    setOpenNewLocationDialog(false);
    console.log(confirmation);
  }
  return (
    <div style={{ backgroundColor: "light" }}>
      <AddNewLocationDialog
        open={openNewLocationDialog}
        // inputs={dialogParams}
        // close={closeDialog}
        // confirm={confirmDialog}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></AddNewLocationDialog>
      <section className="search-sec app-bg-color">
        <div className="container">
          {user && (user.role === RolesConstants.OPERATOR) && <div className="text-danger text-center">This service is not available for Taxi Operators/Dirvers</div>}
          <Form validated={validated} onSubmit={getSearchResults} noValidate>
            <div className="row">
              <div className="col-lg-12">
                <div className="row justify-content-center">
                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                    <div className="form-outline">
                      <Select
                        className={
                          errors?.from?.required
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        options={locations}
                        name="from"
                        placeholder="From"
                        onChange={event => handleSelectChange(event, "from")}
                        onInputChange={searchLocation}
                        noOptionsMessage={event => notFoundMessage()}
                        getOptionLabel={option => getSelectOptionLabel(option)}
                        required
                        onFocus={setInitialValues}
                      />
                    </div>
                  </div>

                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                    <div data-mdb-input-init className="form-outline">
                      <Select
                        className={
                          errors?.to?.required
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        options={locations}
                        name="to"
                        placeholder="To"
                        onChange={event => handleSelectChange(event, "to")}
                        onInputChange={searchLocation}
                        noOptionsMessage={event => notFoundMessage()}
                        getOptionLabel={option => getSelectOptionLabel(option)}
                      />
                    </div>
                  </div>
                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                    <div data-mdb-input-init className="form-outline">
                      <input
                        type="date"
                        className="form-control form-control-lg"
                        name="date"
                        placeholder="Departure Time"
                        value={date || ""}
                        onChange={handleChange}
                        required
                      />
                      <label className="col small text-left text-muted text-white">
                        Departure Date
                      </label>
                    </div>
                  </div>
                  {/* <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                    <div data-mdb-input-init className="form-outline">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="paxcount"
                        max="10"
                        min="1"
                        placeholder="No. of Travelers"
                        value={inputs.paxcount || ""}
                        onChange={handleChange}
                        required
                      />
                      <label className="col small text-left text-muted">
                        Including you (Min: 1, Max: 10)
                      </label>
                    </div>
                  </div>
                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0 mr-2">
                    <Form.Select
                      className="text-capitalize form-select form-select-lg is-1invalid"
                      aria-label="Default select example"
                      id="slot"
                      name="slot"
                      value={inputs.slot || ""}
                      // placeholder="Time Slot"
                      onChange={handleChange}
                      required
                    >
                      <option disabled value={""}>
                        Time Slot
                      </option>
                      {filtersConstants.TimeSlotsArray.map(slot => {
                        return (

                          <option
                            key={slot}
                            value={slot}
                          >
                            {filtersConstants.getTimeSlotString(slot)}
                          </option>
                        );
                      })}
                    </Form.Select>

                  </div> */}
                  <div className="py-3 col-lg-3 col-md-3 col-sm-8 p-0">
                    <div data-mdb-input-init className="1form-outline">
                      <button
                        type="submit"
                        className="font-weight-bold btn btn-lg bg-dark text-white btn-outline-light btn-block"
                        disabled={user && (user.role === RolesConstants.OPERATOR)}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                {user && <div className="text-center">
                  <span
                    onClick={createLocationDialog}
                    className="text-primary btn"
                  >
                    Location not found? Add Here
                  </span>
                </div>}
              </div>
            </div>
          </Form>
        </div>
      </section>
    </div>
  );
}
