import { useEffect, useState } from "react";

import UsersPenal from "./UsersPenal";
import LocationsPenal from "./LocationsPenal";

export default function AdminPenal() {

  const LOCATION = 'location', USERS = 'users';
  const [activeTab, setActiveTab] = useState(LOCATION);

  useEffect(() => {

  }, []);

  const showLocations = () => {
    setActiveTab(LOCATION);
  };

  const showUsers = () => {
    setActiveTab(USERS);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="mt-2 px-0 col-md-11 ">
          <div className="mt-5 container">
            <ul className="1px-2 row nav nav-tabs nav-fill border-none">
              <li id={LOCATION} className="px-0 mx-0 col-auto nav-item">
                <a
                  className={
                    activeTab === LOCATION
                      ? "border-0 nav-link active app-bg-color"
                      : "bg-dark border border-bottom-0 text-white nav-link"
                  }
                  onClick={showLocations}
                  href="#"
                >
                  Locations
                </a>
              </li>
              <li id={USERS} className="px-0 mx-0 col-auto nav-item">
                <a
                  className={
                    activeTab === USERS
                      ? "border-0 nav-link active app-bg-color"
                      : "bg-dark border border-bottom-0 text-white nav-link"
                  }
                  onClick={showUsers}
                  href="#"
                >
                  User
                </a>
              </li>
            </ul>
          </div>
          {activeTab === LOCATION
            &&
            <LocationsPenal></LocationsPenal>
          }
          {activeTab === USERS &&
            <UsersPenal></UsersPenal>
          }
        </div>
      </div>

    </div>
  );
}
