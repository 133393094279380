import { useEffect, useState } from "react";

import useService from "../../hooks/useService";
import { useNavigate } from "react-router-dom";
import AdminService from "../../Services/AdminService";
import UsersEdit from "./UsersEdit";
import UsersProfileEdit from "./UsersProfileEdit";

export default function UsersPenal() {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [showProfileEditUserForm, setShowProfileEditUserForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [reload, setReload] = useState(false);
  const adminService = useService(AdminService);

  useEffect(() => {
    fetchUsers();
  }, [reload]);

  const fetchUsers = () => {
    setLoading(true);
    setShowEditUserForm(false);
    setShowProfileEditUserForm(false);

    adminService.fetchUsers().subscribe({
      next: response => {
        console.log(response.result);
        setUserList(response.result?.data);
        setLoading(false);
      }
    });
  };

  const editUser = (user) => {
    setSelectedUser(user);
    setShowEditUserForm(true);
    setShowProfileEditUserForm(false);
  }

  const editUserProfile = (user) => {
    setSelectedUser(user);
    setShowEditUserForm(false);
    setShowProfileEditUserForm(true);
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="mt-2 px-0">
          <button
            className="m-2 btn btn-outline-dark"
            onClick={fetchUsers}
          >
            Reload
          </button>
          {loading && (
            <div className="my-5 text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {!loading &&
            <div className="table-responsive">

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">S. No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userList && userList.map((user, length = 0) => {
                    return (

                      <tr key={user._id}>
                        <th scope="row">{length + 1}</th>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          {user.status ? user.status : "Active"}
                          {!user.status && <div className="btn btn-sm btn-primary" onClick={() => editUser(user)}>Edit</div>}
                          {!user.status && <div className="btn btn-sm btn-primary" onClick={() => editUserProfile(user)}>Edit Profile</div>}
                        </td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>

      {showEditUserForm &&
        <UsersEdit reload={!reload} selectedUser={selectedUser}></UsersEdit>
      }
      {showProfileEditUserForm &&
        <UsersProfileEdit reload={!reload} selectedUser={selectedUser}></UsersProfileEdit>
      }
    </div>
  );
}
