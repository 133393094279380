import React from "react";
import PathConstants from "./PathConstants";
import Login from "../Components/user/Login";
import Register from "../Components/user/Register";
import Dashboard from "../Components/rides/Dashboard";
import CreateRideComponent from "../Components/rides/CreateRideComponent";
import BookRideComponent from "../Components/rider/BookRideComponent";
import BookingDetailComponent from "../Components/rider/BookingDetailComponent";

import RequireAuth from "../Components/Common/RequireAuth";
import RideDetailComponent from "../Components/rides/RideDetailComponent";
import Page403 from "../pages/Page403";
import SearchResults from "../Components/rides/SearchResults";
import Account from "../Components/user/UserAccount";
import UserAccount from "../Components/user/UserAccount";
import EmailVerify from "../Components/user/EmailVerify";
import EditProfile from "../Components/user/EditProfile";
import ForgotPassword from "../Components/user/ForgotPassword";
import RequireOperator from "../Components/Common/RequireOperator";
import RequireRider from "../Components/Common/RequireRider";
import ContactUs from "../pages/ContactUs";
import CoTravelSearchResults from "../Components/rides/CoTravelSearchResults";
import RequestConstants from "../constants/RequestConstants";
import CoTravelCreateQuery from "../Components/Common/SearchComponent/CoTravelCreateQuery";
import RequireAdmin from "../Components/Common/RequireAdmin";
import AdminPenal from "../Components/Admin/AdminPenal";
import UserProfile from "../Components/user/UserProfile";

const Home = React.lazy(() => import("../pages/Home"));
// const Team = React.lazy(() => import("../pages/Team"));
// const Portfolio = React.lazy(() => import("../pages/Portfolio"));
const About = React.lazy(() => import("../pages/About"));
const requestConstants = RequestConstants();
const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.LOGIN, element: <Login /> },
  { path: PathConstants.REGISTER, element: <Register /> },
  {
    path: PathConstants.DASHBOARD,
    element: (
      <RequireOperator>
        {" "}
        <Dashboard />
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.ACCOUNT,
    element: (
      <RequireRider>
        <UserAccount />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.USER_PROFILE + "/:pathParam",
    element: (
      <RequireRider>
        <UserProfile />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.CREATE_RIDE,
    element: (
      <RequireOperator>
        {" "}
        <CreateRideComponent />{" "}
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.BOOK_RIDE + "/:id",
    element: (
      <RequireAuth>
        {" "}
        <BookRideComponent />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.RIDE_DETAIL + "/:id",
    element: (
      <RequireAuth>
        {" "}
        <RideDetailComponent />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.RIDE_DETAIL,
    element: (
      <RequireAuth>
        {" "}
        <RideDetailComponent />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.BOOKING_DETAIL + "/:id",
    element: (
      <RequireAuth>
        {" "}
        <BookingDetailComponent />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.SEARCH_RESULTS,
    element: (
      // <RequireAuth>
      // {" "}
      <SearchResults />
    ),
  },
  {
    path: PathConstants.COTRAVEL_SEARCH_RESULTS,
    element: (
      <RequireAuth>
        <CoTravelSearchResults />
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.COTRAVEL_CREATE_QUERY,
    element: (
      <RequireAuth>
        <CoTravelCreateQuery />
      </RequireAuth>
    ),
  },

  {
    path: PathConstants.EMAIL_VERIFY,
    element: (
      // <RequireAuth>
      // {" "}
      <EmailVerify />
      // { " "}
      // </RequireAuth>
    ),
  },
  {
    path: PathConstants.EDIT_PROFILE,
    element: (
      <RequireAuth>
        {" "}
        <EditProfile />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.ADMIN_PENAL,
    element: (
      <RequireAdmin>
        {" "}
        <AdminPenal />{" "}
      </RequireAdmin>
    ),
  },
  {
    path: PathConstants.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },

  { path: PathConstants.ABOUT, element: <About /> },
  { path: PathConstants.CONTACT, element: <ContactUs /> },
  { path: PathConstants.PAGE403, element: <Page403 /> },
];
export default routes;
