import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// import { useGet } from "../SearchComponent/useSearchItems";
import { usePost, useGet } from "../Common/useApi";
// import { usePost, useGet } from "../../../Services/UserService/UserService";
import AuthUser from "../Common/AuthUser";
import { useNavigate } from "react-router-dom";
import BookingService from "../../Services/BookingService";
import useService from "../../hooks/useService";
import { throwError } from "rxjs";
import "./booking-detail.css";
import BookingStatus from "../../constants/BookingStatus";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import { toast } from "react-toastify";
import PathConstants from "../../routes/PathConstants";
import {
  faArrowCircleDown,
  faArrowDown,
  faLongArrowAltDown,
  faLongArrowDown,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FiltersConstants from "../../constants/FiltersConstants";

export default function BookingDetailComponent() {
  let { id } = useParams();
  const location = useLocation();
  var data = location.state;
  const [loading, setLoading] = useState(true);
  const [bookingDetail, setBookingDetail] = useState([]);
  const { user } = AuthUser();
  const [showPostCabs, setShowPostCabs] = useState(false);
  const { http } = AuthUser();
  const navigate = useNavigate();
  const [confirmationFromChild, setConfirmationFromChild] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });
  const filtersConstants = FiltersConstants();
  const bookingService = useService(BookingService);

  useEffect(() => {
    setLoading(true);
    console.log(data);
    if (data) {
      id = data._id;
      getBooking(id);
    } else {
      if (id) {
        console.log(id);
        getBooking(id);
      } else {
        toast.dismiss();
        toast.error("Bad Request");
        setLoading(false);
      }
    }
  }, []);
  const getBooking = id => {
    bookingService.get(id).subscribe({
      next: response => {
        if (response.status !== 0) {
          // console.log(response.result);
          data = response.result;
          setBookingDetail(data);
        }
        setLoading(false);
      },
      error: err => {
        // alert("network error");
        setLoading(false);
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };
  const cancelBooking = () => {
    setOpenConfirmationDialog(true);
    var title = "Cancel Booking";

    var body =
      "Do you want to cancel the booking? Once confirmed, it cannot be revoked.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
    }));
  };

  function handleConfirmationFromChild(confirmation) {
    setOpenConfirmationDialog(false);
    console.log(confirmation);
    if (confirmation) {
      doCancelBooking();
    }
  }

  const doCancelBooking = () => {
    console.log(id);
    bookingService.cancelBooking(bookingDetail._id).subscribe({
      next: response => {
        if (response.status !== 0) {
          toast.dismiss();
          toast.success(response.message);
          data = response.result;
          setBookingDetail(data);
        }
        setLoading(false);
      },
      error: err => {
        toast.dismiss();
        setLoading(false);
        toast.error(err.message);
      },
    });
  };
  const navigateToRide = () => {
    navigate(PathConstants.RIDE_DETAIL + "/" + bookingDetail.ride_id);
  };
  return (
    <div className="App booking-detail">
      <ConfirmationDialog
        open={openConfirmationDialog}
        inputs={dialogParams}
        // close={closeDialog}
        // confirm={confirmDialog}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></ConfirmationDialog>
      <div className="container mt-3">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="ride-detail">
            <div className="p-2 row d-flex justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="shadow card">
                  <div className="card-header bg-dark">
                    <strong className="text-white">Booking Detail</strong>
                  </div>
                  <div className="card-body">
                    {/* <div className="d-flex justify-content-between align-items-center"> */}

                    <div className="col p-0">
                      <div className="row">
                        <div className="mb-2 col-8 border-right">
                          <div className="mb-2  text-left">
                            <strong>Booking Id - </strong>
                            <span>{bookingDetail.booking_id}</span>
                          </div>
                          {/* <hr /> */}
                          <div className="mb-2 text-left">
                            <strong>Booked By - </strong>
                            <span>{bookingDetail?.bookedby?.name}</span>
                          </div>
                          {/* <hr /> */}
                          <div className="mb-2 text-left">
                            <strong>Booked At - </strong>
                            <span>
                              {new Date(
                                bookingDetail.created_at
                              ).toLocaleDateString() +
                                " " +
                                new Date(
                                  bookingDetail.created_at
                                ).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  hour12: true, // Delete for 24-hour format
                                  minute: "2-digit",
                                })}
                            </span>
                          </div>
                        </div>
                        <div className="mb-2 px-0 col-4 text-white align-self-center">
                          <strong className="py-2 px-1 text-success">
                            {bookingDetail.status === BookingStatus.CONFIRMED
                              ? BookingStatus.CONFIRMED_NAME
                              : BookingStatus.CANCELLED_NAME}
                          </strong>
                          {bookingDetail.status !== BookingStatus.CANCELLED &&
                            user?._id === bookingDetail.booked_by && (
                              <div
                                className="m-2 btn btn-danger"
                                onClick={cancelBooking}
                              >
                                {" "}
                                Cancel
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="col">
                      <div className="row">
                        <div className="mb-2 col text-left text-success">
                          <strong>
                            {new Date(
                              parseInt(bookingDetail?.ride?.departure)
                            ).toDateString()}
                          </strong>
                        </div>
                      </div>
                      <div className="row">
                        <div className="booking-pickup-drop col-12 col-md-10 1border-right">
                          <div className="d-flex flex-column flex-md-row align-content-center">
                            <div className="col text-break text-wrap mb-2">
                              <div className="text-capitalize">
                                {bookingDetail.pickup_loc?.landmark}
                              </div>
                              <small>
                                {new Date(
                                  parseInt(bookingDetail?.ride?.departure)
                                ).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  hour12: true, // Delete for 24-hour format
                                  minute: "2-digit",
                                })}
                              </small>
                            </div>
                            <div className="py-3 col 1col-sm-4 mb-2 align-self-center">
                              {/* <small>{bookingDetail?.ride?.distance} Km.</small> */}
                              <hr className="d-none d-md-block my-0 flex-fill track-line" />
                              <div className="d-block d-md-none track-arrow">
                                <FontAwesomeIcon
                                  className="px-1 h-100"
                                  icon={faLongArrowDown}
                                />
                              </div>
                              {/* <small>
                              {getHoursFromMilliSec(
                                bookingDetail?.arrival - bookingDetail.departure
                              )}
                              Hrs.
                              {getMinsFromMilliSec(
                                bookingDetail.arrival - bookingDetail.departure
                              )}
                              Mins.
                            </small> */}
                            </div>
                            <div className="col text-break text-wrap mb-2">
                              <div className="text-capitalize">
                                {/* {bookingDetail.drop} */}
                                {bookingDetail.drop_loc?.landmark}
                              </div>
                              <small>
                                {new Date(
                                  parseInt(bookingDetail?.ride?.arrival)
                                ).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  hour12: true, // Delete for 24-hour format
                                  minute: "2-digit",
                                })}
                              </small>
                            </div>
                            {/* <span className="d-flex justify-content-center align-items-center big-dot dot">
                            <i className="fa fa-check text-dark"></i>
                          </span> */}
                          </div>
                        </div>
                        <div className="col text-center">
                          <hr className="px-4 1d-inline d-md-none" />
                          <div className="row">
                            <div className="col">
                              <div className="row">
                                <div className="col">
                                  <strong>
                                    {"Rs. " + bookingDetail?.ride?.fare + "/Pax"}
                                  </strong>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <small>To Be Paid</small>
                                </div>
                              </div>
                              {/* <hr /> */}
                              {/* <div className="row">
                              <div className="col">
                                {bookingDetail.current_vacancy}
                                of {bookingDetail.maxpax} vacant
                              </div>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {/* <div className="col"> */}
                    <div className="col">
                      <div className="row mb-1">
                        <div className=" col text-left">
                          <strong className="1border-left 1border-right 1border-top">
                            Passengers
                          </strong>
                        </div>
                      </div>
                      <div className="row">
                        {bookingDetail?.passengers?.map((item, index) => (
                          <div
                            key={index}
                            className="m-2 mt-3 col-12 border d-flex"
                          >
                            <div className="col-auto">{index + 1} </div>
                            <div className="col text-capitalize">{item.name} </div>
                            <div className="col-auto">{item.age}</div>
                            <div className="col-auto">
                              {filtersConstants.getGenderString(item.gender)}
                            </div>
                            {/* <span className="d-flex justify-content-center align-items-center big-dot dot">
                            <i className="fa fa-check text-dark"></i>
                          </span> */}
                          </div>
                        ))}
                        <div className="text-left"><FontAwesomeIcon className="text-success px-1"
                          icon={faPhone}></FontAwesomeIcon> {bookingDetail?.bookedby?.phone
                            + " - " + bookingDetail?.bookedby?.name}</div>
                      </div>
                    </div>
                    <hr />
                    {/* <div className="col"> */}
                    <div className="col px-2">
                      <div className="row">
                        <div className="col text-left font-weight-bold">
                          Ride
                        </div>
                        <div
                          onClick={navigateToRide}
                          className="col text-primary p-0 btn font-weight-bold"
                        >
                          Detail
                        </div>
                      </div>
                      <div className="row">
                        <div className="px-0 mt-3 col border-right">
                          <div className="d-flex align-items-center">
                            <div className="px-2 col-auto d-flex flex-column text-center">
                              <div className="text-capitalize">
                                {bookingDetail?.ride?.from_loc?.city}
                              </div>
                              <div className="text-capitalize text-muted small">
                                {bookingDetail?.ride?.from_loc?.state}
                              </div>
                              <small className="text-muted">
                                {new Date(
                                  bookingDetail?.ride?.departure
                                ).toDateString()}
                              </small>
                            </div>
                            <div className="col px-0">
                              <hr className="my-0 flex-fill track-line" />
                            </div>

                            <div className="px-2 col-auto d-flex flex-column text-center">
                              <div className="text-capitalize">
                                {bookingDetail?.ride?.to_loc?.city}
                              </div>
                              <div className="text-capitalize text-muted small">
                                {bookingDetail?.ride?.to_loc?.state}
                              </div>

                              {/* <div>{bookingDetail?.ride?.to} </div> */}
                              {bookingDetail?.ride?.arrival && (
                                <small className="text-muted">
                                  {new Date(
                                    bookingDetail.ride.arrival
                                  ).toDateString()}
                                </small>
                              )}
                            </div>
                            {/* <span className="d-flex justify-content-center align-items-center big-dot dot">
                            <i className="fa fa-check text-dark"></i>
                          </span> */}
                          </div>
                          <div className="d-flex mt-3">
                            <div className="text-1 font-weight-bold col-auto text-left">
                              Operator
                              <FontAwesomeIcon
                                className="px-2 text-left text-success "
                                icon={faPhone}
                              />
                            </div>
                            <div className="col pl-0 text-left">
                              {
                                bookingDetail?.ride?.operator?.phone + " - "
                                + bookingDetail?.ride?.operator?.name
                              }
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-6 border-right">
                              <strong>Driver</strong>
                              <hr />
                              <div className="col text-left">
                                <div className="row">
                                  <strong className="col px-0">Name </strong>
                                  <div className="col px-0">
                                    {bookingDetail?.ride?.driver?.name}
                                  </div>
                                </div>
                                <div className="row">
                                  <strong className="col px-0">Phone </strong>
                                  <div className="col px-0">
                                    {bookingDetail?.ride?.driver?.phone}
                                  </div>
                                </div>
                                <div className="row">
                                  <strong className="col px-0">Age </strong>
                                  <div className="col px-0">
                                    {bookingDetail?.ride?.driver?.age}
                                  </div>
                                </div>
                                <div className="row">
                                  <strong className="col px-0">Gender </strong>
                                  <div className="col px-0">
                                    {filtersConstants.getGenderString(
                                      bookingDetail?.ride?.driver?.gender
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <strong>Vehicle</strong>
                              <hr />
                              <div className="col px-0 text-left">
                                <div className="col px-0 text-capitalize">
                                  {/* <strong>Name </strong> */}
                                  {bookingDetail?.ride?.vehicle?.brand}
                                </div>
                                <div className="col px-0 text-capitalize">
                                  {/* <strong>Age </strong> */}
                                  {bookingDetail?.ride?.vehicle?.vehicle_no}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <hr />
                  <div className="col">
                    <div className="row">
                      <div className="col  border-right border-dark">
                        <div className="row">
                          <div className="col mb-2 text-left">
                            <strong className="1lead 1fw-normal">
                              Driver Detail
                            </strong>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <small className="1lead 1fw-normal">
                              {bookingDetail?.driver?.name}
                              {bookingDetail?.driver?.phone
                                ? " | " + bookingDetail.driver.phone
                                : ""}
                              {bookingDetail?.driver?.age
                                ? " | " + bookingDetail.driver.age
                                : ""}
                              {bookingDetail?.driver?.gender
                                ? " | " + bookingDetail.driver.gender
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col mb-2 text-left">
                            <strong className="1lead 1fw-normal">
                              Vehicle Detail
                            </strong>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <small className="1lead 1fw-normal">
                              {bookingDetail?.vehicle?.brand}
                              {bookingDetail?.vehicle?.vehicle_no
                                ? " | " + bookingDetail.vehicle.vehicle_no
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
